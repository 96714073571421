"use client";
import React, { createContext, useContext } from 'react';
import useWebSocket from './useWebSocket';

interface WebSocketContextType {
    data: any | null;
    socket: WebSocket | null;
    isConnected: boolean;
  }
  
  const WebSocketContext = createContext<WebSocketContextType | null>(null);
  
  export const useWebSocketContext = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
      throw new Error("useWebSocketContext must be used within a WebSocketProvider");
    }
    return context;
  };


export const WebSocketProvider = ({ children }: {children: React.ReactNode}) => {
    const [data, socket, isConnected] = useWebSocket(`${process.env.NEXT_PUBLIC_WEBSOCKET_URL}`);
    if (typeof window !== 'undefined' && socket) {
      (window as any).debugWebSocket = socket;
    }
    return (
        <WebSocketContext.Provider value={{data, socket, isConnected}}>
            {children}
        </WebSocketContext.Provider>
    );
};
